var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"plate-center"},[_c('div',{staticClass:"classify yh-h72 level_align yh-ptb0lr20 yh-bbox yh-fz14 yh-mt20"},_vm._l((_vm.NewsNameList),function(item){return _c('div',{key:item.id,staticClass:"yh-w88 center_combo yh-cp",class:{ active: _vm.classifyAct == item.cid },on:{"click":function($event){return _vm.changeNews(item)}}},[_vm._v(_vm._s(item.name))])}),0),_c('ul',{staticClass:"yh-mt30 yh-pb30"},_vm._l((_vm.NewsList),function(item,index){return _c('li',{key:index,staticClass:"yh-h88 yh-fc333 level_align flex_between yh-ptb0lr20 yh-bbox yh-cp",on:{"click":function($event){_vm.pageJumps('RichTextDetails', {
                        router: JSON.stringify([
                            { name: '首页', path: '/' },
                            { name: '新闻资讯', path: '/news' } ]),
                        pageName: '详情',
                        api: 'newsDetail',
						id: item.id
                    },true)}}},[_c('span',{staticClass:"flex-1 ellispsis1 yh-mr20"},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"yh-fc999"},[_vm._v(_vm._s(item.create_time))])])}),0),_c('el-pagination',{staticClass:"center_combo yh-h88",attrs:{"background":"","current-page":_vm.page.page,"page-sizes":[10, 20, 30, 40],"page-size":_vm.page.per_page,"layout":"prev, pager, next","total":_vm.page.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }