<template>
    <div class="plate-center">
        <!-- 面包屑 S -->
        <div class="classify yh-h72 level_align yh-ptb0lr20 yh-bbox yh-fz14 yh-mt20">
            <div
                class="yh-w88 center_combo yh-cp"
                v-for="item in NewsNameList"
                :key="item.id"
                :class="{ active: classifyAct == item.cid }"
                @click="changeNews(item)"
            >{{item.name}}</div>
        </div>
        <!-- 面包屑 E -->

        <!--列表 S-->
        <ul class="yh-mt30 yh-pb30">
            <li
                @click="
                    pageJumps('RichTextDetails', {
                        router: JSON.stringify([
                            { name: '首页', path: '/' },
                            { name: '新闻资讯', path: '/news' },
                        ]),
                        pageName: '详情',
                        api: 'newsDetail',
						id: item.id
                    },true)
                "
                class="yh-h88 yh-fc333 level_align flex_between yh-ptb0lr20 yh-bbox yh-cp"
                v-for="(item,index) in NewsList"
                :key="index"
            >
                <span class="flex-1 ellispsis1 yh-mr20">{{item.title}}</span>
                <span class="yh-fc999">{{item.create_time}}</span>
            </li>
        </ul>
        <!--列表 E-->

        <!-- 分页器 S-->
        <el-pagination
            class="center_combo yh-h88"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="page.per_page"
            layout="prev, pager, next"
            :total="page.total"
        ></el-pagination>
        <!-- 分页器 E-->
    </div>
</template>

<script>
export default {
    name: "news",
    data() {
        return {
            active: "0",
            NewsNameList: [], // 分类列表
            NewsList: [], // 资讯列表
            classifyAct: "", // 分类 被选中 0通知 1工作 2 法律
            page: {
                per_page: 10, // 每页数量
                page: 1, // 页码
                total: null, // 总页数
            },
        };
    },
    created() {
        this.getCategoryList();
    },
    mounted() {},
    methods: {
        //   获取分类列表
        getCategoryList() {
            this.$axios.post(this.$api.newsCategory).then((res) => {
                if (res.code === 1) {
                    this.NewsNameList = res.data;
                    if (res.data.length) {
                        this.classifyAct = res.data[0].cid;
                        this.getList();
                    }
                }
            });
        },
        //   获取列表
        getList() {
            this.$axios
                .post(this.$api.newsList, {
                    cid: this.classifyAct,
                    per_page: this.page.per_page,
                    page: this.page.page,
                })
                .then((res) => {
                    if (res.code === 1) {
                        if (res.data.list.data.length) {
                            this.NewsList = res.data.list.data;
                        }
                        this.page.total = res.data.list.total;
                    }
                });
        },
        // 切换资讯
        changeNews(item) {
            this.classifyAct = item.cid;
            this.page.page = 1;
            this.getList();
        },
        handleSizeChange() {
            this.getList();
        },
        handleCurrentChange(val) {
            this.page.page = val;
            this.getList();
        },
    },
};
</script>

<style scoped lang="scss">
.classify {
    background: #f8f8f8;
    .active {
        font-weight: bold;
        color: #1263ff;
    }
}
ul {
    li {
        &:nth-child(odd) {
            background: #eef9ff;
            border-radius: 3px;
        }
    }
}
</style>
